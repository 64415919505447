import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import loginComponent from './components/login';
import Navbar from './components/navbar';
import TenantList from './components/tenantList';
import Tenant from './components/tenant';
import AddTenant from './components/addTenant';
import xRay from './components/xRay';
import Plugin from './components/plugin';
import AdminEmails from './components/adminEmails';
import MultiTenant from './components/multiTenant';

import './stylesheets/App.scss';
import Dashboard from './components/dashboard';
import OtherComponent from './components/other-component';
import './stylesheets/App.scss';
import Status from './components/status';

let component = () => {
  return (
    <div className="main-container">
      <Navbar />
      <Switch>
        <Route path="/" component={Dashboard} exact />
        <Route path="/tenant" component={TenantList} exact />
        <Route path="/tenant/add" component={AddTenant} exact />
        <Route path="/tenant/:name" component={Tenant} />
        <Route path="/xray" component={xRay} exact />
        <Route path="/other" component={OtherComponent} exact />
        <Route path="/configuration/:name" component={Plugin} />
        <Route path="/admin-emails" component={AdminEmails} exact />
        <Route path="/status" component={Status} exact />
        <Route path="/multi-tenant/:name" component={MultiTenant} />
      </Switch>
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={loginComponent} />
        <Route path="/" component={component} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
