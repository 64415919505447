import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
import axios from '../services/axios';
import '../stylesheets/form.scss';
import '../stylesheets/tenant.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from './breadcrumb';

// Buttons
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// Tooltip
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

// Loader
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Select
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const ColorCircularProgress = withStyles({
  root: {
    color: '#f07539',
  },
})(CircularProgress);

class Tenant extends Component {
  state = {
    data: null,
    isDisabled: true,
    errors: {
      first_name: null,
      last_name: null,
      company_size: null,
      contact_email: null,
      user_limit: null,
      trial_expires_at: null,
      contact_number: null,
      suspended_at: null,
      per_license_cost: null,
      screenshot_auto_delete: null
    },
    isExpired: null,
    defaultCurrency: 'INR',
    currencyList: ['INR'],
  };
  originalState = {
    data: null,
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    axios.get(`/tenant/${params.name}`).then((response) => {
      if (response && response.data) {
        response.data['trial_expires_at'] =
          response.data['trial_expires_at'].length === 24
            ? response.data['trial_expires_at']
            : +response.data['trial_expires_at'];
        this.setState({
          data: response.data,
          isExpired: new Date().getTime() >= new Date(response.data && response.data.trial_expires_at).getTime(),
        });
        if (response.data.currency && response.data.currency.length) {
          this.setState({ defaultCurrency: response.data.currency });
        }
        this.originalState.data = response.data;
      }
    });
  }

  canBeSubmitted = () => {
    const localState = this.state.data;
    const localOriginalState = this.originalState.data;
    let isDisabled = false;
    for (const key in this.state.errors) {
      if (this.state.errors[key] !== null) {
        isDisabled = true;
        break;
      }
    }
    if (isDisabled) {
      this.setState({ isDisabled: true });
      return;
    }

    if (
      localState.first_name === localOriginalState.first_name &&
      localState.last_name === localOriginalState.last_name &&
      localState.company_size === localOriginalState.company_size &&
      localState.contact_email === localOriginalState.contact_email &&
      localState.user_limit === localOriginalState.user_limit &&
      localState.trial_expires_at === localOriginalState.trial_expires_at &&
      localState.status === localOriginalState.status &&
      localState.period_state === localOriginalState.period_state &&
      localState.suspended_at === localOriginalState.suspended_at &&
      localState.contact_number === localOriginalState.contact_number &&
      localState.per_license_cost === localOriginalState.per_license_cost &&
      localState.conflict_resolution === localOriginalState.conflict_resolution &&
      // localState.data_retention_period === localOriginalState.data_retention_period &&
      localState.data_archival_period === localOriginalState.data_archival_period &&
      localState.screenshot_auto_delete_threshold === localOriginalState.screenshot_auto_delete_threshold
    ) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }
  };

  handleCancelClick = () => {
    this.props.history.push(`/tenant`);
  };

  handleSaveClick = () => {
    const {
      match: { params },
    } = this.props;
    const localState = this.state.data;
    const localOriginalState = this.originalState.data;

    let body = {};
    for (let key in localOriginalState) {
      if (localState[key] !== localOriginalState[key]) {
        if (key === 'trial_expires_at') {
          body['trial_expires_at'] = new Date(this.state.data['trial_expires_at']).getTime();
        } else if (key === 'suspended_at') {
          body['suspended_at'] = new Date(this.state.data.suspended_at);
        } else {
          body[key] = localState[key];
        }
      }
    }
    if (localState.contact_email !== localOriginalState.contact_email) {
      body.contact_email = localState.contact_email;
    }

    axios
      .patch(`/tenant/${params.name}`, body)
      .then((response) => {
        if(!response.data) {
          toast.error("Invalid", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          return;
        }
        if (response.data) {
          response.data['trial_expires_at'] =
            response.data['trial_expires_at'].length === 24
              ? response.data['trial_expires_at']
              : +response.data['trial_expires_at'];
        }
        this.setState({
          data: response.data,
          isDisabled: true,
        });
        this.originalState.data = response.data;
        toast.success('Changes saved !', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((e) => {
        let error = 'Generic Error';
        if (e) {
          if (e && e.response && e.response.data && e.response.data.error && e.response.data.error.message) {
            if (Array.isArray(e.response.data.error.message)) {
              let message = '';
              for (let item of e.response.data.error.message) {
                for (let o in item) {
                  message += `${o} : ${item[o]}\n `;
                }
              }
              error = message;
            } else {
              error = e.response.data.error.message;
            }
          } else {
            error = e.message;
          }
        }
        toast.error(error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  handleDeleteClick = (e) => {
    let confirmation = window.confirm('Are you sure you want to delete tenant?');

    if (confirmation) {
      this.toastId = toast(' ⏳ Removing tenant. Please wait...', {
        autoClose: false,
        type: toast.TYPE.INFO,
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      axios
        .delete(`/tenant/${this.state.data.id}`)
        .then((response) => {
          toast.update(this.toastId, {
            render: ' 🚀 Tenant Removed Successfully!',
            position: toast.POSITION.BOTTOM_RIGHT,
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
            onClose: () => this.props.history.push(`/tenant`),
          });
        })
        .catch((error) => {
          console.log('Error', error);
          toast.update(this.toastId, {
            render: ' ⚠️ ' + error,
            position: toast.POSITION.BOTTOM_RIGHT,
            type: toast.TYPE.ERROR,
            autoClose: false,
          });
        });
    }
  };

  handleUserLimitChange = (e) => {
    let value = parseInt(e.target.value || 0);
    if (value >= 0 && value <= 10000) {
      this.setState({ data: { ...this.state.data, user_limit: value } }, this.canBeSubmitted);
    }

    if (e.target.value < 1 || e.target.value > 9999) {
      this.setState({
        ...this.state.errors,
        user_limit: 'User Limit must be between 1 and 99999',
      });
    } else {
      this.setState({
        ...this.state.errors,
        user_limit: null,
      });
    }
  };

  handleLicenseChange = (e) => {
    let errors = this.state.errors;
    if (e.target.value < 0) {
      errors['per_license_cost'] = 'License cost must be greater than 0';
      this.setState({ errors });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          per_license_cost: null,
        },
      });
      this.setState({ data: { ...this.state.data, per_license_cost: +e.target.value } }, this.canBeSubmitted);
    }
  };

  handleUpdateFirstName = e => {
    if (!e.target.value) {
        let errors = this.state.errors;
        errors['first_name'] = 'First Name can not be left blank';
        this.setState({errors})
    } else {
        let errors = this.state.errors;
        errors['first_name'] = null; 
        this.setState({errors})
        this.setState({ data: { ...this.state.data, first_name: e.target.value } }, this.canBeSubmitted);
    }
  };

  handleUpdateLastName = e => {
    if (!e.target.value) {
        let errors = this.state.errors;
        errors['last_name'] = 'Last Name can not be left blank';
        this.setState({errors})
    } else {
        let errors = this.state.errors;
        errors['last_name'] = null; 
        this.setState({errors})
        this.setState({ data: { ...this.state.data, last_name: e.target.value } }, this.canBeSubmitted);
    }
  };

  handleCompanySizeChange = (e) => {
    let value = parseInt(e.target.value || 0);
    if (value >= 0 && value <= 10000) {
      this.setState({ data: { ...this.state.data, company_size: value } }, this.canBeSubmitted);
    }

    if (e.target.value < 1 || e.target.value > 9999) {
      this.setState({
        ...this.state.errors,
        company_size: 'Company Size must be between 1 and 99999',
      });
    } else {
      this.setState({
        ...this.state.errors,
        company_size: null,
      });
    }
  };

  handleEmailChange = (e) => {
    if (e.target.value || e.target.value === '') {
      this.setState({ data: { ...this.state.data, contact_email: e.target.value } }, this.canBeSubmitted);
    }
    function validateEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!validateEmail(e.target.value) || !e.target.value) {
      this.setState({
        errors: {
          ...this.state.errors,
          contact_email: 'Please Enter a valid Email',
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          contact_email: null,
        },
      });
    }
  };

  setStatus = (e) => {
    this.setState({ data: { ...this.state.data, status: e.target.value } }, this.canBeSubmitted);
  };

  setPeriodState = (e) => {
    this.setState({ data: { ...this.state.data, period_state: +e.target.value } }, this.canBeSubmitted);
  };

  setExpiryDate = (e) => {
    if (new Date(e.target.value).toString() === 'Invalid Date') {
      return;
    }
    if (e.target.value) this.setState({ data: { ...this.state.data, trial_expires_at: new Date(e.target.value).getTime() } });
    if (new Date(e.target.value).getTime() > new Date(this.state.data.suspended_at).getTime()) {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            trial_expires_at: 'Please ensure that trail expiry date is before suspended at',
          },
        },
        () => {
          this.canBeSubmitted();
        }
      );
    } else {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            trial_expires_at: null,
          },
        },
        () => {
          this.setSuspendedTime({ target: { value: new Date(this.state.data.suspended_at) } });
        }
      );
    }
  };

  setSuspendedTime = (e) => {
    if (new Date(e.target.value).toString() === 'Invalid Date') {
      return;
    }
    this.setState({ data: { ...this.state.data, suspended_at: e.target.value } }, this.canBeSubmitted);
    if (
      this.state.data.trial_expires_at &&
      new Date(e.target.value).getTime() < new Date(this.state.data.trial_expires_at).getTime()
    ) {
      let errors = this.state.errors;
      errors['suspended_at'] = 'Please ensure that suspended date is greater than trail period';
      this.setState({
        errors,
      });
    } else {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            suspended_at: null,
          },
        },
        () => {
          this.canBeSubmitted();
        }
      );
    }
  };
  handleContactChange = (e) => {
    this.setState({ data: { ...this.state.data, contact_number: e.target.value } }, this.canBeSubmitted);
    if (!e.target.value) {
      let errors = this.state.errors;
      errors['contact_number'] = 'Contact number can not be left blank';
      this.setState({
        errors,
      });
    } else {
      let errors = this.state.errors;
      errors['contact_number'] = null;
      this.setState({
        errors,
      });
    }
  };

  handleChangeCurrency = (e) => {
    let currency = e.target.value;
    let data = this.state.data;
    data['currency'] = currency;
    this.setState({ data: data });
  };

  setValues = (e) => {
    this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } }, this.canBeSubmitted);
  };

  setThreshold = (e) => {
    this.setState({ data: { ...this.state.data, [e.target.name]: e.target.value } }, this.canBeSubmitted);
    const localOriginalState = this.originalState.data;
    if(localOriginalState.screenshot_auto_delete_threshold > 0) {
      if(localOriginalState.screenshot_auto_delete_threshold < parseInt(e.target.value) || parseInt(e.target.value) === 0) {
        let errors = this.state.errors;
        errors['screenshot_auto_delete'] = null;
        this.setState({
          errors,
        });
      } else {
        let errors = this.state.errors;
        errors['screenshot_auto_delete'] = `The value should be greater than current value ${localOriginalState.screenshot_auto_delete_threshold}`;
        this.setState({
          errors,
        });
      }
    }
    if(localOriginalState.screenshot_auto_delete_threshold === 0) {
      if(e.target.value === "0" || parseInt(e.target.value) > 6) {
        let errors = this.state.errors;
        errors['screenshot_auto_delete'] = null;
        this.setState({
          errors,
        });
      } else {
        let errors = this.state.errors;
        errors['screenshot_auto_delete'] = 'The value either equals to 0 or should be greater than 6';
        this.setState({
          errors,
        });
      }
    }
  };

  render() {
    return (
      <div className="page-container">
        {this.state.data ? (
          <>
            <Breadcrumb
              data={[
                {
                  text: 'Tenant',
                  link: '/tenant',
                },
                {
                  text: this.state.data.name,
                  link: null,
                },
              ]}
            />
            <div className="tenant-page-container">
              <form className="tenant-page-form">
                <div className="form-item">
                  <label htmlFor="name" className={`form-label`}>
                    Name<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    id="name"
                    className={`form-input`}
                    placeholder="name"
                    aria-label="name"
                    name="name"
                    value={this.state.data.name}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="firstname" className={`form-label`}>
                    First Name<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    id="firstname"
                    className={`form-input ${this.state.errors.first_name ? 'error' : ''}`}
                    placeholder="first name"
                    aria-label="firstname"
                    name="firstname"
                    value={this.state.data.first_name || ''}
                    onChange={this.handleUpdateFirstName}
                  />
                  {this.state.errors.contact_email && (
                    <label htmlFor="firstname" className={`form-label-error`}>
                      {this.state.errors.first_name}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="lastname" className={`form-label`}>
                    Last Name<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    id="lastname"
                    className={`form-input ${this.state.errors.last_name ? 'error' : ''}`}
                    placeholder="last name"
                    aria-label="lastname"
                    name="lastname"
                    value={this.state.data.last_name || ''}
                    onChange={this.handleUpdateLastName}
                  />
                  {this.state.errors.last_name && (
                    <label htmlFor="lastname" className={`form-label-error`}>
                      {this.state.errors.last_name}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="name" className={`form-label`}>
                   Company Name<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    id="companyname"
                    className={`form-input`}
                    placeholder="company name"
                    aria-label="companyname"
                    name="companyname"
                    value={this.state.data.company_name}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="companysize" className={`form-label ${this.state.errors.company_size ? 'error' : ''}`}>
                    Company Size<sup>*</sup>
                  </label>
                  <input
                    type="number"
                    id="companysize"
                    className={`form-input`}
                    placeholder="company size"
                    aria-label="companysize"
                    name="companysize"
                    value={this.state.data.company_size}
                    onChange={this.handleCompanySizeChange}
                  />
                  {this.state.errors.company_size && (
                    <label htmlFor="companysize" className={`form-label-error`}>
                      {this.state.errors.company_size}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="email" className={`form-label`}>
                    Email<sup>*</sup>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className={`form-input ${this.state.errors.contact_email ? 'error' : ''}`}
                    placeholder="email"
                    aria-label="email"
                    name="email"
                    value={this.state.data.contact_email || ''}
                    onChange={this.handleEmailChange}
                  />
                  {this.state.errors.contact_email && (
                    <label htmlFor="email" className={`form-label-error`}>
                      {this.state.errors.contact_email}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="contact_number" className={`form-label`}>
                    Contact No.<sup>*</sup>
                  </label>
                  <input
                    type="tel"
                    id="contact_number"
                    className={`form-input ${this.state.errors.contact_number ? 'error' : ''}`}
                    placeholder="contact number"
                    aria-label="contact_number"
                    name="contact_number"
                    value={this.state.data.contact_number || ''}
                    pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                    maxLength="15"
                    onChange={this.handleContactChange}
                  />
                  {this.state.errors.contact_number && (
                    <label htmlFor="contact_number" className={`form-label-error`}>
                      {this.state.errors.contact_number}
                    </label>
                  )}
                </div>

                <div className="form-item">
                  <label htmlFor="userLimit" className={`form-label ${this.state.errors.user_limit ? 'error' : ''}`}>
                    User Limit<sup>*</sup>
                  </label>
                  <input
                    type="number"
                    id="userLimit"
                    className={`form-input`}
                    placeholder="userLimit"
                    aria-label="userLimit"
                    name="userLimit"
                    value={this.state.data.user_limit}
                    onChange={this.handleUserLimitChange}
                  />
                  {this.state.errors.user_limit && (
                    <label htmlFor="userLimit" className={`form-label-error`}>
                      {this.state.errors.user_limit}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label
                    htmlFor="licenseCost"
                    className={`form-label  ${this.state.errors.per_license_cost ? 'error' : ''}`}
                  >
                    Per License Cost<sup>*</sup>
                  </label>
                  <input
                    type="number"
                    id="licenseCost"
                    className={`form-input`}
                    placeholder="Per license cost"
                    aria-label="licenseCost"
                    name="licenseCost"
                    value={this.state.data.per_license_cost}
                    onChange={this.handleLicenseChange}
                  />
                  {this.state.errors.per_license_cost && (
                    <label htmlFor="licenseCost" className={`form-label-error`}>
                      {this.state.errors.per_license_cost}
                    </label>
                  )}
                </div>

                <div className="currencyInput form-item">
                  <label htmlFor="currencyInput" className={`form-label`}>
                    Currency<sup>*</sup>
                  </label>
                  <Select id="currencyInput" value={this.state.defaultCurrency} onChange={this.handleChangeCurrency}>
                    {this.state.currencyList.map((list, index) => {
                      return (
                        <MenuItem key={index} value={list}>
                          {list}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <div className="form-item">
                  <label htmlFor="expiryTime" className={`form-label`}>
                    Trail Expires at<sup>*</sup>
                  </label>
                  <input
                    onChange={this.setExpiryDate}
                    type="date"
                    id="expiryTime"
                    className={`form-input  ${this.state.errors.trial_expires_at ? 'error' : ''}`}
                    placeholder="expiryTime"
                    aria-label="expiryTime"
                    name="expiryTime"
                    value={
                      this.state.data.trial_expires_at
                        ? new Date(this.state.data.trial_expires_at).toISOString().substr(0, 10)
                        : new Date().toISOString().substr(0, 10)
                    }
                  />
                  {this.state.errors.trial_expires_at && (
                    <label htmlFor="expiryTime" className={`form-label-error`}>
                      {this.state.errors.trial_expires_at}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="suspendedTime" className={`form-label`}>
                    Suspended at<sup>*</sup>
                  </label>
                  <input
                    onChange={this.setSuspendedTime}
                    type="date"
                    id="suspendedTime"
                    className={`form-input  ${this.state.errors.suspended_at ? 'error' : ''}`}
                    placeholder="suspendedTime"
                    aria-label="suspendedTime"
                    name="suspendedTime"
                    value={
                      this.state.data.suspended_at
                        ? new Date(this.state.data.suspended_at).toISOString().substr(0, 10)
                        : new Date().toISOString().substr(0, 10)
                    }
                  />
                  {this.state.errors.suspended_at && (
                    <label htmlFor="suspendedTime" className={`form-label-error`}>
                      {this.state.errors.suspended_at}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="schemaName" className={`form-label`}>
                    Schema name<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    id="schemaName"
                    className={`form-input`}
                    placeholder="schemaName"
                    aria-label="schemaName"
                    name="schemaName"
                    value={this.state.data.schema}
                  />
                </div>
                {!this.state.isExpired && (
                  <div className="form-item">
                    <label htmlFor="status" className={`form-label`}>
                      Status<sup>*</sup>
                    </label>
                    <div className="form-radio">
                      <input
                        type="radio"
                        name="status"
                        checked={this.state.data.status === 'active'}
                        className="form-radio"
                        id="active"
                        value="active"
                        onChange={this.setStatus}
                      />
                      <label htmlFor="active">Active</label>
                    </div>
                    <div className="form-radio">
                      <input
                        type="radio"
                        name="status"
                        checked={this.state.data.status === 'inactive'}
                        className="form-radio"
                        id="inactive"
                        value="inactive"
                        onChange={this.setStatus}
                      />
                      <label htmlFor="inactive">Inactive</label>
                    </div>
                  </div>
                )}
                {this.state.isExpired && (
                  <div className="form-item-error">
                    <p>The tenant has expired</p>
                  </div>
                )}
                <div className="form-item">
                  <label htmlFor="periodState" className={`form-label`}>
                    Period State<sup>*</sup>
                  </label>
                  <div className="form-radio">
                    <input
                      type="radio"
                      name="trial"
                      checked={this.state.data.period_state === 0}
                      className="form-radio"
                      id="trial"
                      value={0}
                      onChange={this.setPeriodState}
                    />
                    <label htmlFor="trial">Trial</label>
                  </div>

                  <div className="form-radio">
                    <input
                      type="radio"
                      name="trial_failed"
                      checked={this.state.data.period_state === 1}
                      className="form-radio"
                      id="trial_failed"
                      value={1}
                      onChange={this.setPeriodState}
                    />
                    <label htmlFor="trial_failed">Trial Failed</label>
                  </div>

                  <div className="form-radio">
                    <input
                      type="radio"
                      name="paid"
                      checked={this.state.data.period_state === 2}
                      className="form-radio"
                      id="paid"
                      value={2}
                      onChange={this.setPeriodState}
                    />
                    <label htmlFor="paid">Paid</label>
                  </div>

                  <div className="form-radio">
                    <input
                      type="radio"
                      name="paid_failed"
                      checked={this.state.data.period_state === 3}
                      className="form-radio"
                      id="paid_failed"
                      value={3}
                      onChange={this.setPeriodState}
                    />
                    <label htmlFor="paid_failed">Renewal Failed</label>
                  </div>

                  <div className="form-item">
                    <label htmlFor="conflict_resolution" className={`form-label`}>
                      Conflict Resolution<sup>*</sup>
                    </label>
                    <input
                      disabled
                      type="text"
                      id="conflict_resolution"
                      className={`form-input`}
                      placeholder="Conflict Resolution"
                      aria-label="conflict_resolution"
                      name="conflict_resolution"
                      value={this.state.data.conflict_resolution == 0 ? 'Old' : 'New'}
                    />
                  </div>

                  {/* <div className="form-item">
                    <label htmlFor="data_retention_period" className={`form-label`}>
                      Data Retention Period
                    </label>
                    <input
                      type="number"
                      id="data_retention_period"
                      className={`form-input`}
                      placeholder="Data Retention Period"
                      aria-label="data_retention_period"
                      name="data_retention_period"
                      value={this.state.data.data_retention_period}
                      onChange={this.setValues}
                    />
                  </div> */}

                  <div className="form-item">
                    <label htmlFor="data_archival_period" className={`form-label`}>
                      Data Archival Period
                    </label>
                    <input
                      type="number"
                      id="data_archival_period"
                      className={`form-input`}
                      placeholder="Data Archival Period"
                      aria-label="data_archival_period"
                      name="data_archival_period"
                      value={this.state.data.data_archival_period}
                      onChange={this.setValues}
                    />
                  </div>

                  <div className="form-item">
                    <label htmlFor="screenshot_auto_delete" className={`form-label`}>
                      <span>Screenshot Auto Delete Threshold</span>
                      <span style={{position: "relative", top: "2px", marginLeft: "8px", cursor: "pointer"}} data-tip data-for="tooltipWithBullets">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 11.5C8.75 11.5 11 9.25 11 6.5C11 3.75 8.75 1.5 6 1.5C3.25 1.5 1 3.75 1 6.5C1 9.25 3.25 11.5 6 11.5Z" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M6 4.5V7" stroke="#475467" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M5.99725 8.5H6.00174" stroke="#475467" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </span>
                      <ReactTooltip id="tooltipWithBullets" place="top" effect="solid" className="custom-tooltip">
                        <div>The value can be set to:</div>
                        <ul style={{ margin: 0, padding: 0, listStyleType: "disc", paddingLeft: "20px" }}>
                          <li>0: Persist data for a lifetime.</li>
                          <li>7 or greater: Automatically delete data after the specified number of days.</li>
                        </ul>
                        <div>Once set:</div>
                        <ul style={{ margin: 0, padding: 0, listStyleType: "disc", paddingLeft: "20px" }}>
                          <li>You can reset the value to 0 for lifetime persistence.</li>
                          <li>Or, update it to a number greater than the current value.</li>
                        </ul>
                      </ReactTooltip>
                    </label>
                    <input
                      type="number"
                      id="screenshot_auto_delete_threshold"
                      className={`form-input  ${this.state.errors.screenshot_auto_delete ? 'error' : ''}`}
                      placeholder="Screenshot Auto Delete Threshold"
                      aria-label="screenshot_auto_delete_threshold"
                      name="screenshot_auto_delete_threshold"
                      value={this.state.data.screenshot_auto_delete_threshold}
                      onChange={this.setThreshold}
                    />
                    {this.state.errors.screenshot_auto_delete && (
                      <label htmlFor="screenshot_auto_delete" className={`form-label-error`}>
                        {this.state.errors.screenshot_auto_delete}
                      </label>
                    )}
                  </div>
                </div>
                <div className="form-item button">
                  <div className="button-wrapper">
                    <Tooltip TransitionComponent={Zoom} title="Remove" placement="top">
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={this.handleDeleteClick}
                        className="removeTenant"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <div className="button-wrapper-with-margin">
                    <Button color="default" onClick={this.handleCancelClick}>
                      Cancel
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      id="button-save"
                      disabled={this.state.isDisabled}
                      onClick={this.handleSaveClick}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <ToastContainer autoClose={4000} />
          </>
        ) : (
          <div className="progressBar">
            <ColorCircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default Tenant;
