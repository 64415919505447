import React, { Component } from "react";
import axios from "../services/axios";
import "../stylesheets/form.scss";
import "../stylesheets/tenant.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumb from "./breadcrumb";
import { Switch } from "@material-ui/core";

// Buttons
import Button from "@material-ui/core/Button";

// Loader
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const ColorCircularProgress = withStyles({
  root: {
    color: "#f07539",
  },
})(CircularProgress);

class Plugin extends Component {
  state = {
    data: null,
    knitIntegration: { allow_third_party_integration: false },
    keyboardMouseLogger: { status: false, enableFeature: false },
    isDisabled: true,
    errors: {
      windows_plugin: null,
      stealth_mode: null,
      ubuntu_plugin: null,
      mac_plugin: null,
    },
  };
  originalState = {
    data: null,
    knitIntegration: { allow_third_party_integration: false },
    keyboardMouseLogger: { status: false, enableFeature: false },
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    axios
      .get(`/configuration/${params.name}/PLUGIN_LATEST_RELEASE`)
      .then((response) => {
        if (response && response.data) {
          this.setState({
            data: JSON.parse(response.data[0].value),
          });
          this.originalState.data = JSON.parse(response.data[0].value);
        }
      });
    axios
      .get(`/configuration/${params.name}/THIRD_PARTY_INTEGRATION`)
      .then((response) => {
        if (response && response.data) {
          this.setState({
            knitIntegration: JSON.parse(response.data[0].value),
          });
          this.originalState.knitIntegration = JSON.parse(
            response.data[0].value
          );
        }
      });
    axios
      .get(`/configuration/${params.name}/CAPTURE_KEYBOARD_MOUSE_EVENTS`)
      .then((response) => {
        if (response && response.data) {
          this.setState({
            keyboardMouseLogger: JSON.parse(response.data[0].value),
          });
          this.originalState.keyboardMouseLogger = JSON.parse(
            response.data[0].value
          );
        }
      });
  }

  canBeSubmitted = () => {
    const localState = this.state.data;
    const knit = this.state.knitIntegration;
    const keyLogger = this.state.keyboardMouseLogger;
    const localOriginalState = this.originalState.data;
    const knitOriginalState = this.originalState.knitIntegration;
    const keyLoggerOriginalState = this.originalState.keyboardMouseLogger;
    let isDisabled = false;
    for (const key in this.state.errors) {
      if (this.state.errors[key] !== null) {
        isDisabled = true;
        break;
      }
    }
    if (isDisabled) {
      this.setState({ isDisabled: true });
      return;
    }

    if (
      localState.value === localOriginalState.value &&
      localState.stealth === localOriginalState.stealth &&
      localState.ubuntu === localOriginalState.ubuntu &&
      localState.mac === localOriginalState.mac &&
      knit.allow_third_party_integration === knitOriginalState.allow_third_party_integration &&
      keyLogger.enableFeature === keyLoggerOriginalState.enableFeature
    ) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }
  };

  handleCancelClick = () => {
    this.props.history.push(`/tenant`);
  };

  handleSaveClick = () => {
    const {
      match: { params },
    } = this.props;
    const localState = this.state.data;
    const localOriginalState = this.originalState.data;
    let pluginChange = false;
    if(localState['value'] !== localOriginalState['value'] ||
      localState['stealth'] !== localOriginalState['stealth'] ||
      localState['ubuntu'] !== localOriginalState['ubuntu'] ||
      localState['mac'] !== localOriginalState['mac']) {
        pluginChange = true;
      } else {
        pluginChange = false;
      }
    const knit = this.state.knitIntegration;
    const keyLogger = this.state.keyboardMouseLogger;
    const knitOriginalState = this.originalState.knitIntegration;
    const keyLoggerOriginalState = this.originalState.keyboardMouseLogger;

    let body = localOriginalState;
    let knitBody = knit;
    let keyBody = keyLogger;

    if(localState['value'] !== localOriginalState['value']) {
      body["value"] = this.state.data["value"];
    }
    if(localState['stealth'] !== localOriginalState['stealth']) {
      body["stealth"] = this.state.data["stealth"];
    }
    if(localState['ubuntu'] !== localOriginalState['ubuntu']) {
      body["ubuntu"] = this.state.data["ubuntu"];
    }
    if(localState['mac'] !== localOriginalState['mac']) {
      body["mac"] = this.state.data["mac"];
    }

    if(knit['allow_third_party_integration'] !== knitOriginalState['allow_third_party_integration']) {
      knitBody['allow_third_party_integration'] = this.state.knitIntegration['allow_third_party_integration'];
    }

    if(keyLogger['enableFeature'] !== keyLoggerOriginalState['enableFeature']) {
      keyBody['enableFeature'] = this.state.keyboardMouseLogger['enableFeature'];
    }

    if(pluginChange) {
      axios
        .post("/configuration/update", {
          schema: `${params.name}`,
          key: "PLUGIN_LATEST_RELEASE",
          value: body,
        })
        .then((response) => {
          this.setState({
            isDisabled: true,
          });
          toast.success("Changes saved !", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((e) => {
          let error = "Generic Error";
          if (e) {
            console.log(e, e.response);
            if (
              e &&
              e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message
            ) {
              if (Array.isArray(e.response.data.error.message)) {
                let message = "";
                for (let item of e.response.data.error.message) {
                  for (let o in item) {
                    message += `${o} : ${item[o]}\n `;
                  }
                }
                error = message;
              } else {
                error = e.response.data.error.message;
              }
            } else {
              error = e.message;
            }
          }
          toast.error(error + "asd", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
    
    if(knit['allow_third_party_integration'] !== knitOriginalState['allow_third_party_integration']) {
      axios
        .post("/configuration/update", {
          schema: `${params.name}`,
          key: "THIRD_PARTY_INTEGRATION",
          value: knitBody,
        })
        .then((response) => {
          this.setState({
            isDisabled: true,
          });
          toast.success("Changes saved !", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((e) => {
          let error = "Generic Error";
          if (e) {
            if (
              e &&
              e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message
            ) {
              if (Array.isArray(e.response.data.error.message)) {
                let message = "";
                for (let item of e.response.data.error.message) {
                  for (let o in item) {
                    message += `${o} : ${item[o]}\n `;
                  }
                }
                error = message;
              } else {
                error = e.response.data.error.message;
              }
            } else {
              error = e.message;
            }
          }
          toast.error(error + "asd", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }

    if(keyLogger['enableFeature'] !== keyLoggerOriginalState['enableFeature']) {
      axios
        .post("/configuration/update", {
          schema: `${params.name}`,
          key: "CAPTURE_KEYBOARD_MOUSE_EVENTS",
          value: keyBody,
        })
        .then((response) => {
          this.setState({
            isDisabled: true,
          });
          toast.success("Changes saved !", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((e) => {
          let error = "Generic Error";
          if (e) {
            if (
              e &&
              e.response &&
              e.response.data &&
              e.response.data.error &&
              e.response.data.error.message
            ) {
              if (Array.isArray(e.response.data.error.message)) {
                let message = "";
                for (let item of e.response.data.error.message) {
                  for (let o in item) {
                    message += `${o} : ${item[o]}\n `;
                  }
                }
                error = message;
              } else {
                error = e.response.data.error.message;
              }
            } else {
              error = e.message;
            }
          }
          toast.error(error + "asd", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
    this.componentDidMount();
  };

  handleWindowsPluginChange = (e) => {
    if (e.target.value || e.target.value === "") {
      let value = e.target.value;
      this.setState(
        { data: { ...this.state.data, value: value } },
        this.canBeSubmitted
      );
    }
    if (!e.target.value) {
      this.setState({
        errors: {
          ...this.state.errors,
          windows_plugin: "Please Enter a URL",
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          windows_plugin: null,
        },
      });
    }
  };

  handleStealthModeChange = (e) => {
    if (e.target.value || e.target.value === "") {
      let value = e.target.value;
      this.setState(
        { data: { ...this.state.data, stealth: value } },
        this.canBeSubmitted
      );
    }
    if (!e.target.value) {
      this.setState({
        errors: {
          ...this.state.errors,
          stealth_mode: "Please Enter a URL",
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          stealth_mode: null,
        },
      });
    }
  };

  handleUbuntuPluginChange = (e) => {
    if (e.target.value || e.target.value === "") {
      let value = e.target.value;
      this.setState(
        { data: { ...this.state.data, ubuntu: value } },
        this.canBeSubmitted
      );
    }
    if (!e.target.value) {
      this.setState({
        errors: {
          ...this.state.errors,
          ubuntu_plugin: "Please Enter a URL",
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          ubuntu_plugin: null,
        },
      });
    }
  };

  handleMACPluginChange = (e) => {
    if (e.target.value || e.target.value === "") {
      let value = e.target.value;
      this.setState(
        { data: { ...this.state.data, mac: value } },
        this.canBeSubmitted
      );
    }
    if (!e.target.value) {
      this.setState({
        errors: {
          ...this.state.errors,
          mac_plugin: "Please Enter a URL",
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          mac_plugin: null,
        },
      });
    }
  };

  changeKnitIntegration = (event) => {
    const { checked } = event.target;
    this.setState({
      knitIntegration: {
        ...this.state.knitIntegration,
        allow_third_party_integration: checked,
      }
    }, this.canBeSubmitted);
  };

  changeKeyboardMouseLogger = (event) => {
    const { checked } = event.target;
    this.setState({
      keyboardMouseLogger: {
        ...this.state.keyboardMouseLogger,
        enableFeature: checked,
      },
    }, this.canBeSubmitted);
  };

  render() {
    return (
      <div className="page-container">
        {this.state.data ? (
          <>
            <Breadcrumb
              data={[
                {
                  text: "Configuration",
                  link: "/tenant",
                },
                {
                  text: this.props.match.params.name,
                  link: null,
                },
              ]}
            />
            <div className="tenant-page-container">
              <form className="tenant-page-form">
                <div className="form-item" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <label htmlFor="knitIntegration" className={`form-label`}>
                    Knit Integration
                  </label>
                  <Switch
                    checked={
                      this.state.knitIntegration.allow_third_party_integration
                    }
                    onChange={this.changeKnitIntegration}
                    color="primary"
                  />
                </div>
                <div className="form-item" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <label htmlFor="keyLogger" className={`form-label`}>
                    Keyboard & Mouse Events
                  </label>
                  <Switch
                    checked={
                      this.state.keyboardMouseLogger.enableFeature
                    }
                    onChange={this.changeKeyboardMouseLogger}
                    color="primary"
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="schemaName" className={`form-label`}>
                    Schema name
                  </label>
                  <input
                    disabled
                    type="text"
                    id="schemaName"
                    className={`form-input`}
                    placeholder="schemaName"
                    aria-label="schemaName"
                    name="schemaName"
                    value={this.props.match.params.name}
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="windows" className={`form-label`}>
                    Windows Plugin
                  </label>
                  <input
                    type="text"
                    id="windows"
                    className={`form-input ${
                      this.state.errors.windows_plugin ? "error" : ""
                    }`}
                    placeholder="Windows Plugin"
                    aria-label="windows"
                    name="windows"
                    value={this.state.data.value}
                    onChange={this.handleWindowsPluginChange}
                  />
                  {this.state.errors.windows_plugin && (
                    <label htmlFor="windows" className={`form-label-error`}>
                      {this.state.errors.windows_plugin}
                    </label>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="stealth_mode" className={`form-label`}>
                    Stealth Mode
                  </label>
                  <input
                    type="text"
                    id="stealth_mode"
                    className={`form-input ${
                      this.state.errors.stealth_mode ? "error" : ""
                    }`}
                    placeholder="stealth mode"
                    aria-label="stealth_mode"
                    name="stealth_mode"
                    value={this.state.data.stealth || ""}
                    onChange={this.handleStealthModeChange}
                  />
                  {this.state.errors.stealth_mode && (
                    <label
                      htmlFor="stealth_mode"
                      className={`form-label-error`}
                    >
                      {this.state.errors.stealth_mode}
                    </label>
                  )}
                </div>

                <div className="form-item">
                  <label
                    htmlFor="ubuntu"
                    className={`form-label ${
                      this.state.errors.ubuntu_plugin ? "error" : ""
                    }`}
                  >
                    Ubuntu
                  </label>
                  <input
                    type="text"
                    id="ubuntu"
                    className={`form-input`}
                    placeholder="ubuntu"
                    aria-label="ubuntu"
                    name="ubuntu"
                    value={this.state.data.ubuntu || ""}
                    onChange={this.handleUbuntuPluginChange}
                  />
                  {this.state.errors.ubuntu_plugin && (
                    <label htmlFor="ubuntu" className={`form-label-error`}>
                      {this.state.errors.ubuntu_plugin}
                    </label>
                  )}
                </div>

                <div className="form-item">
                  <label
                    htmlFor="mac"
                    className={`form-label ${
                      this.state.errors.ubuntu_plugin ? "error" : ""
                    }`}
                  >
                    Mac
                  </label>
                  <input
                    type="text"
                    id="mac"
                    className={`form-input`}
                    placeholder="Mac"
                    aria-label="mac"
                    name="mac"
                    value={this.state.data.mac || ""}
                    onChange={this.handleMACPluginChange}
                  />
                  {this.state.errors.mac_plugin && (
                    <label htmlFor="mac" className={`form-label-error`}>
                      {this.state.errors.mac_plugin}
                    </label>
                  )}
                </div>
                <div className="form-item button">
                  <div className="button-wrapper-with-margin">
                    <Button color="default" onClick={this.handleCancelClick}>
                      Cancel
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      id="button-save"
                      disabled={this.state.isDisabled}
                      onClick={this.handleSaveClick}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <ToastContainer autoClose={4000} />
          </>
        ) : (
          <div className="progressBar">
            <ColorCircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default Plugin;
